<template>
  <div>
    <div class="container my-5">
      <div class="row text-center">
        <div class="col-md-10 mx-auto">
          <h1>UK Retreat Information</h1>
          <p style="color: orange">
            Important: Please bookmark this page, this is your retreat
            information page.
          </p>
          <button type="button" id="bookmark" class="btn btn-green-gradient">
            <i class="fa fa-bookmark me-1"></i>BOOKMARK THIS PAGE
          </button>
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />
          <div class="row my-5 text-left">
            <div class="col-md-12">
              <h4 style="color: #83c550">Dr Patricia Saintey</h4>
            </div>
            <div class="col-md-9 my-auto">
              <p>
                Dr Patricia Saintey began her medical career as a doctor in the
                army and as a regular serving officer. After leaving the army
                she took up General Practice in Somerset where she worked full
                time as a partner in a practice in Chard for 9 years. A
                prolonged sabbatical, forced initially by her own health,
                enabled her to undergo a variety of studies looking into
                nutrition, homeopathy, craniosacral therapy and finally
                embarking on a Diploma in Integrative Medicine looking into the
                evidence behind complimentary therapies.
              </p>
            </div>
            <div class="col-md-3 my-auto">
              <img
                src="@/assets/images/uk-team/patricia.gif"
                alt="Dr Patricia Saintey"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-0 mb-5">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />
          <div class="row text-center py-4">
            <div class="col-md-12">
              <h3 style="color: #83c550">How to Prepare</h3>
              <a
                href="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2022/06/How-to-Prepare-UK.pdf"
                target="_blank"
                class="btn btn-lg btn-green-gradient mt-3"
                >DOWNLOAD RETREAT PREPARATION TIPS</a
              >
            </div>
          </div>
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />

          <!--  -->
          <div class="row text-center py-4">
            <div class="col-md-12">
              <h3 style="color: #83c550">How to Find Us</h3>
              <h5 style="color: orange">Bindon House</h5>
              <a
                href="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2013/02/directions-bindon-house.pdf"
                target="_blank"
                class="btn btn-lg btn-green-gradient mt-3"
                >DOWNLOAD DIRECTIONS</a
              >
            </div>
          </div>
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />

          <!--  -->
          <div class="row text-center py-4">
            <div class="col-md-12">
              <h3 style="color: #83c550">Treatment Overview</h3>

              <router-link
                to="/treatments"
                class="btn btn-lg btn-green-gradient mt-3"
                >WHICH TREATMENT & WHY! FIND OUT MORE</router-link
              >
            </div>
          </div>
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />

          <!--  -->
          <div class="row text-center py-4">
            <div class="col-md-12">
              <h3 style="color: #83c550">Reserve Treatments</h3>

              <a
                href="https://clients.amchara.com/login"
                class="btn btn-lg btn-green-gradient mt-3"
                >BOOK YOUR TREAMENTS ONLINE</a
              >
            </div>
          </div>
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />

          <!--  -->
          <div class="row text-center py-4">
            <div class="col-md-12">
              <h3 style="color: #83c550">Raw Food Manual</h3>

              <a
                href="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2022/06/Raw-Food-Manual.pdf"
                target="_blank"
                class="btn btn-lg btn-green-gradient mt-3"
                >DOWNLOAD RAW FOOD & RECIPE MANUAL</a
              >
            </div>
          </div>
          <hr
            style="
              border-color: #83c550;
              border-style: dashed;
              border-width: 2px;
            "
          />

          <!--  -->
          <div class="row text-center py-4">
            <div class="col-md-12">
              <h3 style="color: #83c550">Need to know more?</h3>

              <router-link
                to="/enquiries"
                class="btn btn-lg btn-orange-gradient mt-3"
                >MAKE AN ENQUIRY HERE</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
};
</script>

<style>
</style>